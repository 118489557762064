.form-screen {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 490px;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 4px 0px inset;
    transition: all 0.5s ease;
  }
  .agreement-otp-screen{
    bottom: -33%;
  }
  .form-screen .title {
    font-size: 20px;
    font-weight: bold;
    color: black
  }
  
  .form-screen .message {
    color: #a3a3a3;
    font-size: 14px;
    margin-top: 4px;
    text-align: center
  }
  
  .form-screen .inputs {
    margin-top: 10px
  }
  
  .form-screen .inputs input {
    width: 32px;
    height: 32px;
    text-align: center;
    border: none;
    border-bottom: 1.5px solid #9dc913;
    margin: 0 10px;
  }
  
  .form-screen .inputs input:focus {

    outline: none;
  }
  
  .form-screen .action {
    margin-top: 24px;
    padding: 12px 16px;
    border-radius: 8px;
    border: none;
  background-color: #9dc913;
    color: white;
    cursor: pointer;
    align-self: end;
  }
  .form-screen .action.me-2{
    background-color: #5a4343;
  }
  .text-danger{
    font-size: 14px;
  }
  .modal-container-body::-webkit-scrollbar{ 
    width: 8px;
    height: 30px;  
  }
  .modal-container-body::-webkit-scrollbar-thumb{
    background-color: #9dc913;
    border-radius: 18px;
  }
  .modal-container-body::-webkit-scrollbar-track{
   box-shadow: inset 0 0 6px #d1ec80;
    border-radius: 18px;
  }
  .next_btn{
    background-color: #9dc913;
  }
  /* From Uiverse.io by abrahamcalsin */ 
.dot-spinner {
  --uib-size: 1.8rem;
  --uib-speed: .9s;
  --uib-color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 18%;
  width: 18%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

  @media screen and (max-width: 770px) {
    .form-screen{
      width: 80vw;
      bottom: -40px;
      padding: 20px 10px;
    }
    .form-screen .inputs input{
      margin: 0 5px;
    }
  }
  